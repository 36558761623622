import React from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";

import arrow from "src/assets/images/svg/arrow-promotion.svg";
// import leaf from "src/assets/images/svg/promotion-leaf.svg";
import leafTop from "src/assets/images/svg/promotion-leaf-top.svg";

import Title from "src/components/typography/Title.js";
import SubTitle from "src/components/typography/SubTitle.js";
import BodyText from "src/components/typography/BodyText.js";
import promotions from "src/assets/data/promotions.js";
import promotionsListLocal from "src/assets/data/promotionsListLocal.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import Something from "src/assets/images/svg/footer-leaf.inline.svg";
import leaf from "src/assets/images/svg/footer-leaf.svg";

const Wrapper = styled.section`
  background-color: ${colors.green};
  padding: 30rem 0;
  position: relative;
  overflow: hidden;
`;

const StyledMainWrapper = styled(MainWrapper)`
  position: relative;
`;

const SmallTextTop = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.light};
  font-family: "sofia-pro", sans-serif;

  display: inline-block;
  margin-bottom: 95rem;
`;

const StyledTitle = styled(Title)`
  color: ${colors.light};
  width: 25%;
  &.topmargin {
    margin-top: 95rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    order: 3;
    &.first {
      width: 80%;
      text-align: right;
    }
  }
`;

const StyledSubTitle = styled(SubTitle)`
  color: ${colors.light};
  margin-bottom: 20rem;
  font-size: ${typographySizes.m}rem;
`;

const StyledBodyText = styled(BodyText)`
  color: ${colors.light};
`;

const PromotionRightCol = styled.div`
  width: 40%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    margin-bottom: 20rem;
  }
`;

const PromotionsWrapper = styled.div`
  margin-top: 40rem;
  position: relative;
`;

const PromotionLeafWrapper = styled.div`
  position: absolute;
  left: 25%;
  top: 0;
  transform: translateY(-100%);
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    display: none;
  }
`;
const PromotionLeaf = styled.img`
  width: 200rem;
`;

const PromotionLeafTop = styled.div`
  position: absolute;
  right: 0;
  top: -20rem;
  width: 280rem;

  transform: rotate(180deg);

  @media (max-width: ${mediaQuery.tablet}) {
    right: -20rem;
    width: 220rem;
  }
`;

const arrowAnimation = keyframes`
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(70rem);
  }
  51% {
    transform: translateX(-70rem);
  }
  100% {
    transform: translateX(0rem);
  }
`;

const ArrowButton = styled.div`
  border: solid 1rem ${colors.light};
  border-radius: 80rem;
  height: 80rem;
  width: 80rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    height: 40rem;
    width: 40rem;
    img {
      height: 20rem;
      width: 20rem;
    }
  }
`;

const PromotionRow = styled.a`
  display: flex;
  border-bottom: solid 1rem ${colors.light};
  padding: 20rem ${distances.gap}rem;
  left: -${distances.gap}rem;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  position: relative;
  align-items: center;
  flex-wrap: wrap;
  :first-of-type {
    border-top: solid 1rem ${colors.light};
  }
  :hover {
    background-color: ${colors.green};
    transition: background-color 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    ${ArrowButton} {
      img {
        animation: 0.5s ${arrowAnimation} ease;
      }
    }
  }
`;

const Promotions = () => {
  return (
    <Wrapper id="promocje">
      <PromotionLeafTop>
        <Something
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
        />
      </PromotionLeafTop>
      <StyledMainWrapper>
        <SmallTextTop
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          zobacz aktualne
        </SmallTextTop>
        <StyledTitle
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          promocje na{"\u00A0"}wynos
        </StyledTitle>
        <PromotionsWrapper>
          <PromotionLeafWrapper>
            <PromotionLeaf
              data-sal="slide-up"
              data-sal-delay="1000"
              data-sal-easing="ease"
              data-sal-duration="500"
              src={leaf}
            />
          </PromotionLeafWrapper>

          {promotions.map((promotion) => (
            <PromotionRow
              data-sal="slide-up"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
              href={promotion.link}
              target="_blank"
            >
              <StyledTitle className="first">{promotion.first}</StyledTitle>
              <PromotionRightCol>
                <StyledSubTitle>{promotion.main}</StyledSubTitle>
                <StyledBodyText>{promotion.description}</StyledBodyText>
              </PromotionRightCol>

              <ArrowButton>
                <img src={arrow} alt="arrow" />
              </ArrowButton>
            </PromotionRow>
          ))}
        </PromotionsWrapper>
        <StyledTitle
          className="topmargin"
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          promocje w{"\u00A0"}lokalu
        </StyledTitle>
        <PromotionsWrapper>
          <PromotionLeafWrapper>
            <PromotionLeaf
              data-sal="slide-up"
              data-sal-delay="1000"
              data-sal-easing="ease"
              data-sal-duration="500"
              src={leaf}
            />
          </PromotionLeafWrapper>

          {promotionsListLocal.map((promotion) => (
            <PromotionRow
              data-sal="slide-up"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              <StyledTitle className="first">{promotion.first}</StyledTitle>
              <PromotionRightCol>
                <StyledSubTitle>{promotion.main}</StyledSubTitle>
                <StyledBodyText>{promotion.description}</StyledBodyText>
              </PromotionRightCol>
              <ArrowButton>
                <img src={arrow} alt="arrow" />
              </ArrowButton>
            </PromotionRow>
          ))}
        </PromotionsWrapper>
      </StyledMainWrapper>
    </Wrapper>
  );
};

export default Promotions;
