import React from "react";
import styled from "styled-components";

import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";
import { StaticImage } from "gatsby-plugin-image";

import { Swiper, SwiperSlide } from "swiper/react";
import { useSwiper } from "swiper/react";
import "swiper/css";

import arrow from "src/assets/images/svg/order-arrow.svg";
import Leaf from "src/assets/images/svg/footer-leaf.inline.svg";

import MainWrapper from "src/components/global/MainWrapper.js";
const StyledWrapper = styled(MainWrapper)`
  margin: 0rem 0 0rem 0;
  position: relative;
  background-color: ${colors.green};
  overflow: hidden;
`;

const Wrapper = styled.section`
  padding: 40rem 0rem;
`;
const Title = styled.h2`
  font-size: ${typographySizes.m}rem;
  text-transform: uppercase;
  position: relative;
  color: ${colors.white};
  line-height: 1.1;
  ::after {
    content: "";
    height: 1rem;
    background-color: white;
    position: absolute;
    bottom: -40rem;
    left: 0;
    width: 200rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: 180rem;
  }
`;

const TestimonialsWrapper = styled.div`
  margin-top: 70rem;
  width: 100%;
  .swiper-slide {
    height: 37vw;
    overflow: hidden;
    position: relative;

    @media (max-width: ${mediaQuery.tablet}) {
      height: 115vw;
    }
  }
  .swiper {
    overflow: visible;
    .gatsby-image-wrapper {
      position: static;
    }
    img {
      width: 100%;
      object-fit: contain !important;
      object-position: top !important;
      top: 0%;
      left: 50%;
      transform: translate(-50%, 0%);
      @media (max-width: ${mediaQuery.tablet}) {
        /* width: 58%; */
      }
    }
  }
`;

const ControlWrapper = styled.div`
  display: flex;
  /* background-color: red; */

  position: absolute;
  transform: translateY(-100%);
  right: 0;
  /* margin-left: 30rem; */
  top: -30rem;
  z-index: 100;
  @media (max-width: ${mediaQuery.tablet}) {
    /* top: -40rem;
    right: 0;
    transform: translate(50%, -100%); */
  }
`;

const RightArrow = styled.div`
  width: 50rem;
  cursor: pointer;
  background-color: white;
  height: 50rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  border-radius: 50rem;
  background-image: url(${arrow});
  background-position: center;
  background-repeat: no-repeat;
  :hover {
    transform: translateY(-5rem);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const LeftArrow = styled.div`
  width: 50rem;
  height: 50rem;
  margin-right: 20rem;
  background-color: white;
  border-radius: 50rem;

  background-image: url(${arrow});
  background-position: center;
  background-repeat: no-repeat;
  transform: rotate(180deg);
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  :hover {
    transform: translateY(-5rem) rotate(180deg);
    transition: all 0.3s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const LeafWrapper = styled.div`
  position: absolute;
  left: 55%;
  transform: translateX(-50%);
  bottom: 0;
  width: 320rem;
`;

const Controls = () => {
  const swiper = useSwiper();

  return (
    <ControlWrapper>
      <LeftArrow onClick={() => swiper.slidePrev()} />
      <RightArrow onClick={() => swiper.slideNext()} />
    </ControlWrapper>
  );
};

const Testimonials = (props) => (
  <StyledWrapper>
    <Wrapper>
      <LeafWrapper
      // data-sal="slide-up"
      // data-sal-easing="ease"
      // data-sal-duration="500"
      // data-sal-delay="800"
      >
        {" "}
        <Leaf />{" "}
      </LeafWrapper>
      <TestimonialsWrapper>
        {" "}
        <Swiper
          spaceBetween={30}
          breakpoints={{
            // when window width is >= 640px
            0: {
              slidesPerView: 1,
            },
            // when window width is >= 768px
            768: {
              slidesPerView: 3,
            },
          }}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
          grabCursor={true}
        >
          <Controls />
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia8.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia5.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia1.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia4.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia2.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia6.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia7.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
          <SwiperSlide>
            <StaticImage
              src="../assets/images/img/opinie/opinia3.jpg"
              placeholder="none"
              layout="fullWidth"
              quality="100"
            />
          </SwiperSlide>
        </Swiper>
      </TestimonialsWrapper>
    </Wrapper>
  </StyledWrapper>
);

export default Testimonials;
