import React, { useRef, useEffect, useState } from "react";

import styled, { keyframes } from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";

import { StaticImage } from "gatsby-plugin-image";
import arrow from "src/assets/images/svg/order-arrow.svg";

import SubTitle from "src/components/typography/SubTitle.js";
import BodyText from "src/components/typography/BodyText.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import mapa from "src/assets/images/img/pink_vegan_mapa_dojazdu.jpg";
import { Link } from "gatsby";

const Wrapper = styled(MainWrapper)`
  margin: 75rem 0 90rem 0;
  position: relative;
`;

const SmallTextTop = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.pink};
  font-family: "sofia-pro", sans-serif;
  display: inline-block;
  margin-bottom: 95rem;
`;

const OrderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledSubTitle = styled(SubTitle)`
  color: ${colors.green};
  width: 100%;
  position: relative;

  ::before {
    content: "";
    position: absolute;
    right: 0;
    bottom: -10rem;
    height: 1rem;
    background-color: ${colors.green};
    width: 100%;
  }
  ::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -10rem;
    height: 1rem;
    background-color: ${colors.green};
    width: 0%;
  }
`;

const StyledBodyText = styled(BodyText)`
  margin-top: 15rem;
  max-width: 165rem;
`;

const ArrowWrapper = styled.div`
  border: solid 1rem ${colors.green};
  height: 40rem;
  width: 40rem;
  border-radius: 40rem;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-end;
  overflow: hidden;
`;

const arrowAnimation = keyframes`
  0% {
    transform: translateX(0rem);
  }
  50% {
    transform: translateX(30rem);
  }
  51% {
    transform: translateX(-30rem);
  }
  100% {
    transform: translateX(0rem);
  }
`;

const OrderBox = styled.div`
  width: 16.66%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    ${ArrowWrapper} {
      img {
        animation: 0.5s ${arrowAnimation} ease;
      }
    }
    ${StyledSubTitle} {
      ::before {
        width: 0%;
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      ::after {
        width: 100%;
        transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 100%;
    order: 4;
    margin-top: 50rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 0rem;
  }
`;

const LinkBox = styled.a`
  width: 16.66%;
  display: flex;
  flex-direction: column;

  justify-content: space-between;
  justify-content: space-between;
  cursor: pointer;
  :hover {
    ${ArrowWrapper} {
      img {
        animation: 0.5s ${arrowAnimation} ease;
      }
    }
    ${StyledSubTitle} {
      ::before {
        width: 0%;
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      ::after {
        width: 100%;
        transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 22%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(50% - ${distances.gap / 2}rem);
    min-height: 100rem;
    margin-bottom: 50rem;
  }
`;

const PinkBox = styled.a`
  width: 16.66%;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  ::before {
    content: "";
    position: absolute;
    left: -25rem;
    top: -25rem;
    height: calc(100% + 50rem);
    width: calc(100% + 50rem);
    border: solid 1rem ${colors.pink};
    z-index: -100;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  :hover {
    ::before {
      background-color: ${colors.light};
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
    ${ArrowWrapper} {
      img {
        animation: 0.5s ${arrowAnimation} ease;
      }
    }
    ${StyledSubTitle} {
      ::before {
        width: 0%;
        transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      }
      ::after {
        width: 100%;
        transition: all 0.5s 0.1s cubic-bezier(0.65, 0, 0.35, 1);
      }
    }
  }
  @media (max-width: ${mediaQuery.desktop}) {
    width: 22%;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(50% - ${distances.gap / 2}rem);
    margin-bottom: 50rem;
    ::before {
      content: "";
      position: absolute;
      left: -10rem;
      top: -10rem;
      height: calc(100% + 20rem);
      width: calc(100% + 20rem);
    }
  }
`;

const MapWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  margin-top: 50rem;
  position: relative;
  top: 50rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  @media (max-width: ${mediaQuery.desktop}) {
    top: 0rem;
  }
`;

const Order = () => {
  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://www.fbgcdn.com/embedder/js/ewm2.js";
    script.async = true;
    script.defer = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, ["https://www.fbgcdn.com/embedder/js/ewm2.js"]);

  const map = useRef(null);
  const [mapH, setMapH] = useState(0);
  const [curentMapH, setCurentMapH] = useState(null);
  const [openMap, setOpenMap] = useState(true);

  useEffect(() => {
    const height = map.current.offsetHeight;

    setMapH(height);

    setTimeout(function () {
      setCurentMapH(0);
    }, 100);
  }, [map]);

  const mapClick = () => {
    setOpenMap(openMap ? false : true);

    openMap ? setCurentMapH(mapH) : setCurentMapH(0);
  };

  return (
    <Wrapper id="dostawa">
      <SmallTextTop
        data-sal="slide-down"
        data-sal-delay="200"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        zobacz aktualne
      </SmallTextTop>
      <OrderWrapper>
        <OrderBox
          data-sal="slide-down"
          data-sal-easing="ease"
          data-sal-duration="500"
          onClick={mapClick}
        >
          <StyledSubTitle>mapa dowozu</StyledSubTitle>
          <StyledBodyText>Zobacz gdzie dowozimy</StyledBodyText>
          <ArrowWrapper>
            <img src={arrow} alt="arrow" />
          </ArrowWrapper>
        </OrderBox>
        <PinkBox
          data-sal="slide-down"
          data-sal-easing="ease"
          data-sal-duration="500"
          target="_blank"
          href="https://www.kimidori.pl/menu"
        >
          <StyledSubTitle>pink vegan</StyledSubTitle>
          <StyledBodyText>Zamawiasz u nas, najlepsza oferta</StyledBodyText>
          <ArrowWrapper>
            <img src={arrow} alt="arrow" />
          </ArrowWrapper>
        </PinkBox>
        <LinkBox
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
          href="https://www.pyszne.pl/menu/pink-vegan"
          target="_blank"
        >
          <StyledSubTitle>pyszne</StyledSubTitle>

          <ArrowWrapper>
            <img src={arrow} alt="arrow" />
          </ArrowWrapper>
        </LinkBox>
        <LinkBox
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
          href="https://food.bolt.eu/pl-pl"
          target="_blank"
        >
          <StyledSubTitle>bolt food</StyledSubTitle>

          <ArrowWrapper>
            <img src={arrow} alt="arrow" />
          </ArrowWrapper>
        </LinkBox>
        <LinkBox
          data-sal="slide-down"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
          href="https://www.ubereats.com/pl/store/pink-vegan/FwPkgGdVUKusRgxlT00YCw"
          target="_blank"
        >
          <StyledSubTitle>uber eats</StyledSubTitle>

          <ArrowWrapper>
            <img src={arrow} alt="arrow" />
          </ArrowWrapper>
        </LinkBox>
      </OrderWrapper>
      <MapWrapper
        ref={map}
        style={{
          height: curentMapH + "px",
          marginTop: openMap ? 0 : 50 + "px",
        }}
      >
        <StaticImage
          src="../..//assets/images/img/pink_vegan_mapa_dojazdu.jpg"
          placeholder="none"
          layout="fullWidth"
          quality="100"
        />
      </MapWrapper>
    </Wrapper>
  );
};

export default Order;
