import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

const Title = styled.h2`
  font-size: ${typographySizes.l}rem;
  line-height: 1.1;
  color: ${colors.light};
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.l / 2}rem;
  }
`;

export default Title;
