import * as React from "react";
import Header from "src/components/header/Header.js";
import FirstSection from "src/components/firstSection/FirstSection.js";
import About from "src/components/about/About.js";
import Promotions from "src/components/promotions/Promotions.js";
import Order from "src/components/order/Order.js";
import Newsletter from "src/components/newsletter/Newsletter.js";
import Tiles from "src/components/tiles/Tiles.js";
import Footer from "src/components/footer/Footer.js";
import fbImg from "src/assets/images/img/fb.jpg";
import favicon from "src/assets/images/svg/favicon.svg";
import { Helmet } from "react-helmet";
import Cookies from "src/components/global/cookies/Cookies.js";
import Testimonials from "src/components/Testimonials.js";
import Map from "src/components/map/Map.js";
import Lunch from "src/components/lunch/Lunch.js";

const IndexPage = () => {
  return (
    <>
      <Helmet
        htmlAttributes={{ lang: "pl" }}
        titleTemplate={`Pink Vegan – restauracja wegańska`}
        title={`Pink Vegan – restauracja wegańska`}
      >
        <link rel="icon" type="image/svg+xml" href={favicon} />
        <meta
          name="description"
          content="Pełna smaku i zdrowa kuchnia wegańska.
          Odkryj na nowo wegańskie smaki"
        />
        <meta name="image" content="../static/fb.jpeg" />
        <meta property="og:image" content={fbImg} />
        <meta property="og:image:secure_url" content={fbImg} />
        <meta property="og:url" content="http://pinkvegan.pl/" />
        <meta property="og:title" content="Pink Vegan" />

        <meta property="og:description" content="restauracja wegańska" />
      </Helmet>
      <Cookies />
      <Header />
      {/* <FirstSection /> */}
      <Promotions />
      <About />
      <Lunch />
      <Testimonials />
      <Map />
      {/* <Newsletter /> */}
      <Tiles />
      <Footer />
    </>
  );
};

export default IndexPage;
