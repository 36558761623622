import React, { useState, useRef, useEffect } from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

import { StaticImage } from "gatsby-plugin-image";

import arrow from "src/assets/images/svg/slider-arrow.svg";
import Title from "src/components/typography/Title.js";
import BodyText from "src/components/typography/BodyText.js";
import MainWrapper from "src/components/global/MainWrapper.js";

const Wrapper = styled.section`
  background-color: ${colors.pink};
  width: 100%;

  ${MainWrapper} {
    display: flex;
    @media (max-width: ${mediaQuery.tablet}) {
      flex-direction: column;
    }
  }
`;

const LeftWrapper = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding: 100rem 0;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    padding: 50rem 0;
  }
`;

const InfoWrapper = styled.div`
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.light};
  max-width: 450rem;
`;

const StyledBodyText = styled(BodyText)`
  max-width: 380rem;
  margin-top: 30rem;
  color: ${colors.light};
`;

const SliderWrapper = styled.div`
  width: calc(50% + ${distances.gap}rem);

  position: relative;
  left: ${distances.gap}rem;
  /* height: 50vw; */
  overflow: hidden;
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% + ${distances.gap}rem);
    left: -${distances.gap / 2}rem;
    height: 120vw;
    background-color: red;
  }
`;

const ArrowSlider = styled.img`
  /* cursor: pointer; */
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  /* :hover {
    transform: translateX(20rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    align-self: flex-end;
  }
`;

const SliderImgWrapper = styled.div`
  width: 100%;
  position: relative;
  flex-shrink: 0;
  height: 750rem;

  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
  .imgwrapper {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  img {
    height: 750rem;
    object-position: center;
    @media (max-width: ${mediaQuery.tablet}) {
      height: 120vw;
      object-fit: cover;
    }
  }
`;

const FirstSection = () => {
  const [sliderPos, setSliderPos] = useState(0);

  useInterval(() => {
    if (sliderPos <= 5) {
      setSliderPos(sliderPos + 1);
    } else {
      setSliderPos(0);
    }
  }, 2000);

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  return (
    <Wrapper>
      <MainWrapper>
        <LeftWrapper>
          <InfoWrapper>
            <StyledTitle
              data-sal="slide-up"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Odkryj nasz roślinny lunch
            </StyledTitle>
            <StyledBodyText
              data-sal="slide-up"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Codziennie inny, codziennie smaczny, zawsze w atrakcyjnej cenie
            </StyledBodyText>
          </InfoWrapper>
        </LeftWrapper>
        <SliderWrapper>
          {/* <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              placeholder="blurred"
              src="../..//assets/images/img/slider/1.JPG"
              alt="PinkVegan"
              objectFit="cover"
              height="100%"
              className="imgwrapper"
            />
          </SliderImgWrapper> */}
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_1.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_2.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_3.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_4.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_5.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_6.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
          <SliderImgWrapper
            style={{ transform: `translateX(-${sliderPos * 100}%)` }}
          >
            <StaticImage
              src="../..//assets/images/img/slider/lunch_7.jpg"
              alt="PinkVegan"
            />
          </SliderImgWrapper>
        </SliderWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default FirstSection;
