import React from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import MainWrapper from "src/components/global/MainWrapper.js";
import Tile from "src/components/tiles/Tile.js";
import img1 from "src/assets/images/img/cards/pinkvegan_1.jpg";
import img2 from "src/assets/images/img/cards/pinkvegan_2.jpg";
import img3 from "src/assets/images/img/cards/pinkvegan_3.jpg";
import img4 from "src/assets/images/img/cards/pinkvegan_4.jpg";
import img5 from "src/assets/images/img/cards/pinkvegan_5.jpg";
import img6 from "src/assets/images/img/cards/pinkvegan_6.jpg";
import mediaQuery from "src/assets/styles/mediaQuery";

const StyledMainWrapper = styled(MainWrapper)`
  position: relative;
  display: flex;
  flex: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${distances.gap}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: ${distances.gap / 2}rem;
  }
`;

const data = [
  {
    smallTop: "zdrowa, współczesna",
    main: "smaczna kuchnia",
    smallBottom: "wegańska",
    img: img1,
  },
  {
    smallTop: "smaczna, etyczna",
    main: "zdrowa ",
    smallBottom: "kuchnia wegańska",
    img: img2,
  },
  {
    smallTop: "bezkompromisowo",
    main: "wegańscy",
    smallBottom: "odkryj to sam",
    img: img3,
  },
  {
    smallTop: "think Pink  ",
    main: "zieloni do roślinnego szpiku",
    smallBottom: "go Green",
    img: img4,
  },
  {
    smallTop: "100%",
    main: "świadomi weganie",
    smallBottom: "wegańskie przepisy",
    img: img5,
  },
  {
    smallTop: "weganie",
    main: "z pysznego wyboru",
    smallBottom: "place for happy vegans",
    img: img6,
  },
];

const Tiles = () => {
  return (
    <StyledMainWrapper>
      {data.map((pos) => (
        <Tile
          img={pos.img}
          top={pos.smallTop}
          mid={pos.main}
          bottom={pos.smallBottom}
        />
      ))}
    </StyledMainWrapper>
  );
};

export default Tiles;
