import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

const SubTitle = styled.h2`
  font-size: ${typographySizes.m}rem;
  font-weight: 400;
  line-height: 1.1;
  color: ${colors.light};
  font-family: "sofia-pro", sans-serif;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;

export default SubTitle;
