import React from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import leaf from "src/assets/images/svg/leaf-about.svg";
import Title from "src/components/typography/Title.js";
import SubTitle from "src/components/typography/SubTitle.js";

import BodyText from "src/components/typography/BodyText.js";
import MainWrapper from "src/components/global/MainWrapper.js";

const StyledMainWrapper = styled(MainWrapper)`
  position: relative;
`;

const SmallTextTop = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.pink};
  font-family: "sofia-pro", sans-serif;
  margin-top: ${distances.gap}rem;
  display: inline-block;
  margin-bottom: 95rem;
`;

const StyledTitle = styled(Title)`
  color: ${colors.green};
`;

const ParagraphWrapper = styled.div`
  margin-top: 20rem;
  display: flex;
  margin-bottom: 90rem;

  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const StyledBodyText = styled(BodyText)`
  width: 41.67%;
  margin-right: 8.33%;
  :last-of-type {
    display: none;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    display: none;
    :last-of-type {
      display: block;
    }
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-left: 50%;
  color: ${colors.green};
  padding-bottom: 80rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0%;
  }
`;

const Leaf = styled.img`
  position: absolute;
  bottom: 0;
  right: 16.66%;
`;

const About = () => {
  return (
    <StyledMainWrapper id="onas">
      <SmallTextTop
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        o pink vegan
      </SmallTextTop>
      <StyledTitle
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        think pink eat green!
      </StyledTitle>
      <ParagraphWrapper>
        <StyledBodyText
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Pink Vegan i Kimidori to dwa wegańskie koncepty, które początkowo
          działały w formacie dark kitchen, a od września ubiegłego roku
          połączyły siły w jednej restauracji na warszawskim Powiślu. Dwóch
          doświadczonych szefów kuchni czuwa nad menu. Dbamy o jakość produktów,
          szanujemy środowisko, pokazujemy, że brak kompromisów smakuje
          wyśmienicie. Wegańska kuchnia nie oznacza wyrzeczeń, to inne smaczne
          wybory.
        </StyledBodyText>
        <StyledBodyText
          data-sal="slide-up"
          data-sal-delay="200"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Tworzymy zupełnie nowe dania pełne umami. Naturalne roślinne składniki
          podane w sposób, który wzbudzi Wasz zachwyt - taki cel postawiliśmy
          przed sobą tworząc menu. Nasze wegańskie dania są proste, naturalne,
          bezkompromisowo etyczne. Ty lubisz sushi, a Twoi przyjaciele wolą
          ramen, lub mają ochotę na wege burgera? Zapraszamy do nas na Browarną
          6. Tu każdy znajdzie coś dla siebie.
        </StyledBodyText>
        <StyledBodyText
          data-sal="slide-up"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          Pink Vegan i Kimidori to dwa wegańskie koncepty, które początkowo
          działały w formacie dark kitchen, a od września ubiegłego roku
          połączyły siły w jednej restauracji na warszawskim Powiślu. Dwóch
          doświadczonych szefów kuchni czuwa nad menu. Dbamy o jakość produktów,
          szanujemy środowisko, pokazujemy, że brak kompromisów smakuje
          wyśmienicie. Wegańska kuchnia nie oznacza wyrzeczeń, to inne smaczne
          wybory. Tworzymy zupełnie nowe dania pełne umami. Naturalne roślinne
          składniki podane w sposób, który wzbudzi Wasz zachwyt - taki cel
          postawiliśmy przed sobą tworząc menu. Nasze wegańskie dania są proste,
          naturalne, bezkompromisowo etyczne. Ty lubisz sushi, a Twoi
          przyjaciele wolą ramen, lub mają ochotę na wege burgera? Zapraszamy do
          nas na Browarną 6. Tu każdy znajdzie coś dla siebie.
        </StyledBodyText>
      </ParagraphWrapper>
      <StyledSubTitle
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="500"
      >
        go vegan or go home!
      </StyledSubTitle>
      <Leaf
        src={leaf}
        data-sal="slide-up"
        data-sal-delay="300"
        data-sal-easing="ease"
        data-sal-duration="500"
      />
    </StyledMainWrapper>
  );
};

export default About;
