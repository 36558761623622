import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

const ButtonWrapper = styled.a`
  display: inline-block;
  height: 50rem;
  text-decoration: none;
  padding: 0 30rem;
  cursor: pointer;
  border-radius: 9rem;
  line-height: 50rem;
  text-align: center;
  font-family: "sofia-pro", sans-serif;

  font-size: ${typographySizes.s}rem;
  border: solid 1rem ${colors.green};
  color: ${colors.white};
  background-color: ${colors.green};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  ${(props) => {
    if (props.secondary) {
      return `   color: ${colors.green};
    background-color: transparent;  `;
    } else if (props.tertiary) {
      return `     background-color: transparent; 
        color: ${colors.white};
        border:  solid 1rem ${colors.white};`;
    }
  }}
  margin-right: ${(props) => props.mRight && "20rem"};
  margin-top: 50rem;
  :hover {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    color: ${colors.green};
    background-color: transparent;
    ${(props) => {
      if (props.secondary) {
        return `   color: ${colors.white};
    background-color: ${colors.green};  `;
      } else if (props.tertiary) {
        return `     background-color: ${colors.white}; 
        color: ${colors.green};
        border:  solid 1rem ${colors.white};`;
      }
    }}
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 20rem;
    min-width: 120rem;
  }
`;

const Button = (props) => (
  <ButtonWrapper
    secondary={props.secondary}
    tertiary={props.tertiary}
    mRight={props.mRight}
    href={props.file}
    target="_blank"
  >
    {props.children}
  </ButtonWrapper>
);

export default Button;
