import React, { useRef, useEffect, useState } from "react";
import addToMailchimp from "gatsby-plugin-mailchimp";
import typographySizes from "src/assets/styles/typographySizes.js";
import colors from "src/assets/styles/colors.js";
import styled, { keyframes } from "styled-components";
import mediaQuery from "src/assets/styles/mediaQuery";
import Title from "src/components/typography/Title.js";
import BodyText from "src/components/typography/BodyText.js";
import distances from "src/assets/styles/distances";
import ellipse from "src/assets/images/svg/newsletter-ellipse.svg";
import Parallax from "parallax-js"; // Now published on NPM

import MainWrapper from "src/components/global/MainWrapper.js";

const Wrapper = styled.section`
  background-color: ${colors.light};
  padding: 25rem 0 125rem 0;
  position: relative;
  overflow: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 25rem 0 50rem 0;
  }
`;

const EllipseWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  div {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const Ellipse = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const SmallTextTop = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.pink};
  font-family: "sofia-pro", sans-serif;
  display: inline-block;
  margin-bottom: 95rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const TextCol = styled.div`
  width: 41.66%;
  z-index: 4;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const StyledTitle = styled(Title)`
  color: ${colors.green};
  max-width: 540rem;
`;

const StyledBodyText = styled(BodyText)`
  margin-top: 15rem;
  max-width: 500rem;
`;

const FormWrapper = styled.div`
  display: flex;
  width: 50%;

  flex-wrap: wrap;
  align-self: center;
  position: relative;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  form {
    position: relative;
    width: 100%;
  }
  .classFieldset {
    border: none !important;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const InputButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 30rem;
  }
  @media (max-width: 450px) {
    flex-direction: column;
  }
`;

const StyledImput = styled.input`
  font-size: ${typographySizes.s}rem;

  background-color: ${colors.white};
  height: 76rem;
  border: none;
  padding-left: 25rem;
  width: calc(66.66% - ${distances.gap}rem);
  margin-right: ${distances.gap}rem;
  font-family: "sofia-pro", sans-serif;
  outline: none;
  &::-webkit-input-placeholder {
    color: ${colors.light};
    font-size: ${typographySizes.s}rem;
    font-family: "sofia-pro", sans-serif;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    max-width: initial;
  }
  @media (max-width: 450px) {
    width: calc(100% - ${distances.gap}rem);
    border: solid 1rem white;
  }
`;

const success = keyframes`
   0%, 100% {
    transform: translateY(0%);
  }
  25% {
    transform: translateY(-100%);
  }
  77% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(-200%);
  }
`;

const Button = styled.button`
  display: inline-block;
  height: 76rem;
  padding: 0 30rem;
  width: calc(33.33%);
  line-height: 76rem;
  text-align: center;
  font-size: ${typographySizes.s}rem;
  cursor: pointer;
  color: ${colors.green};
  overflow: hidden;
  border: solid 1rem ${colors.green};
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  font-family: "sofia-pro", sans-serif;
  background-color: ${colors.light};
  @media (max-width: 450px) {
    width: 100%;
    margin-top: 10rem;
    margin-left: 0rem;
  }

  span {
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    display: block;
    position: relative;
    top: -2px;
    font-size: inherit;
    color: inherit;
    font-family: inherit;
  }
  &.success {
    span {
      animation: ${success} 3s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Message = styled.div`
  margin-top: 10rem;
  font-size: 10rem;
  color: ${colors.pink};
  font-family: "sofia-pro", sans-serif;
`;
const StyledFieldset = styled.fieldset`
  margin-top: 30rem;
`;

const StyledCheckmark = styled.span`
  display: block;

  position: relative;
  top: 2rem;
  width: 12rem;
  height: 12rem;
  margin: -1rem 0rem 0 0;

  vertical-align: middle;
  margin-right: 10rem;
  cursor: pointer;
  background-color: ${colors.white};
  border-radius: 100%;
  flex-shrink: 0;
`;

const LabelWrapper = styled.label`
  display: flex;
  .gdpr {
    display: none;
  }
  .gdpr:checked + ${StyledCheckmark} {
    background-color: ${colors.pink};
  }
`;

const GdprText = styled.span`
  font-size: 10rem;
  color: ${colors.green};
  font-family: "sofia-pro", sans-serif;

  max-width: 640rem; ;
`;
const MailChimpForm = () => {
  const [email, setEmail] = useState("");
  const [gdpr, setGdpr] = useState();
  const [result, setResult] = useState("");
  const [msg, setMsg] = useState(null);
  const [isChecked, setisChecked] = useState(false);
  const [success, setSuccess] = useState(false);

  const _handleSubmit = async (e) => {
    e.preventDefault();
    if (isChecked) {
      const result = await addToMailchimp(email, { "gdpr[357182]": "Y" });
      setResult(result);

      setMsg(result.msg);
      if (result.result === "success") {
        setSuccess(true);
        setInterval(function () {
          setSuccess(false);
        }, 3100);
      }
    } else {
      setMsg("Wyrażenie zgody jest wymagane");
    }
  };
  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  const handleChangeGdpr = (event) => {
    const premision = {
      marketing_permissions: [
        { marketing_permission_id: "gdpr[357182]", enabled: true }, //
      ],
    };
    setGdpr(premision);
  };

  const selectShortlistedApplicant = (e) => {
    const checked = e.target.checked;
    if (!isChecked) {
      setisChecked(true);
    } else {
      setisChecked(false);
    }
  };
  const sceneEl1 = useRef(null);
  useEffect(() => {
    const parallaxInstance1 = new Parallax(sceneEl1.current, {
      relativeInput: true,
      hoverOnly: false,
    });
  }, []);

  return (
    <Wrapper>
      <MainWrapper>
        <EllipseWrapper ref={sceneEl1}>
          <div data-depth=".30">
            {" "}
            <Ellipse src={ellipse} />{" "}
          </div>
        </EllipseWrapper>
        <SmallTextTop
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          bądź na bieżąco
        </SmallTextTop>
        <ContentWrapper>
          <TextCol>
            <StyledTitle
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              dołącz do nas i&nbsp;bądź na bieżąco
            </StyledTitle>
            <StyledBodyText
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Zapisz się do newslettera i&nbsp;bądź na bieżąco z&nbsp;obecnymi
              promocjami i&nbsp;ofertami.{" "}
              <span> Nie przegap żadnej okazji! </span>
            </StyledBodyText>
          </TextCol>
          <FormWrapper
            data-sal="slide-up"
            data-sal-delay="500"
            data-sal-easing="ease"
            data-sal-duration="500"
          >
            <form onSubmit={_handleSubmit}>
              <InputButtonWrapper
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="500"
              >
                <StyledImput
                  id="outlined-email-input"
                  placeholder="podaj Email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  variant="outlined"
                  onChange={handleChange}
                />
                <Button
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className={success ? "success" : ""}
                >
                  <span>zapisz się</span>
                  <span>dziękujemy</span>
                  <span>zapisz się</span>
                </Button>
              </InputButtonWrapper>

              <StyledFieldset
                data-sal="slide-up"
                data-sal-easing="ease"
                data-sal-duration="500"
                name="interestgroup_field"
                className="classFieldset mc_fieldset gdprRequired mc-field-group"
              >
                <LabelWrapper
                  for="gdpr_357182"
                  onClick={(e) => {
                    selectShortlistedApplicant(e);
                  }}
                >
                  <input
                    type="checkbox"
                    id="gdpr_357182 acceptTerms"
                    name="gdpr[357182]"
                    value="Y"
                    className="av-checkbox gdpr"
                    type="checkbox"
                    onChange={handleChangeGdpr}
                    checked={isChecked}
                  />
                  <StyledCheckmark />
                  <GdprText>
                    Wyrażam zgodę na przetwarzanie moich danych osobowych w celu
                    prowadzenia przez Sushi Point s.c. z siedzibą w Warszawie
                    marketingu bezpośredniego.
                    {result.msg ? (
                      <Message> {msg}</Message>
                    ) : (
                      <Message> </Message>
                    )}
                    {!isChecked ? (
                      <Message> {msg}</Message>
                    ) : (
                      <Message> </Message>
                    )}
                  </GdprText>
                </LabelWrapper>
              </StyledFieldset>
            </form>
          </FormWrapper>
        </ContentWrapper>
      </MainWrapper>
    </Wrapper>
  );
};

export default MailChimpForm;
