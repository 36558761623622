import React from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";

import mediaQuery from "src/assets/styles/mediaQuery";

import Title from "src/components/typography/Title.js";
import SubTitle from "src/components/typography/SubTitle.js";

import greenCard from "src/assets/images/svg/flipCard-green.svg";
import pinkCard from "src/assets/images/svg/flipCard-pink.svg";

const Card = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;

  transition: all 1s ease;
  transform-style: preserve-3d;
  /* box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2); */
`;

const CardFront = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;

  backface-visibility: hidden;
  background-color: #bbb;
  color: black;
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;

  transform-style: preserve-3d;
`;

const CardBack = styled.div`
  position: absolute;
  width: 100%;
  height: calc(100% - 300rem);

  background-image: url(${greenCard});
  background-position: center;
  background-size: cover;
  padding: 150rem 0;
  transform-style: preserve-3d;
  transform: rotateY(-180deg);
  backface-visibility: hidden;
  @media (max-width: ${mediaQuery.tablet}) {
    padding: 50rem 0;
    height: calc(100% - 100rem);
  }
`;

const CardWrapper = styled.div`
  position: relative;
  background-color: transparent;
  width: calc(33.33% - ${distances.gap / 2}rem);
  height: 45vw;
  margin-bottom: ${distances.gap}rem;
  perspective: 1500rem; /* Remove this if you don't want the 3D effect */
  :hover {
    ${Card} {
      transform: rotateY(180deg);
    }
  }
  :nth-last-of-type(2n) {
    ${CardBack} {
      background-image: url(${pinkCard});
    }
  }
  &:nth-of-type(1n) {
    transition-delay: 0.4s;
  }
  &:nth-of-type(2n) {
    transition-delay: 0.6s;
  }
  &:nth-of-type(3n) {
    transition-delay: 0.8s;
  }

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(50% - ${distances.gap / 4}rem);
    height: 65vw;
    margin-bottom: ${distances.gap / 2}rem;

    :nth-child(n + 5) {
      display: none;
    }
    &:nth-of-type(1n) {
      transition-delay: 0.4s;
    }
    &:nth-of-type(2n) {
      transition-delay: 0.6s;
    }
  }
`;

const StyledTitle = styled(Title)`
  font-size: 5vw;
  transform: translateZ(100px) scale(0.98);
  perspective: inherit;
  display: block;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: 7.5vw;
  }
`;

const CardDepth = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  transform: translateZ(70rem) scale(0.98);
  perspective: inherit;
  @media (max-width: ${mediaQuery.tablet}) {
    transform: translateZ(20rem) scale(0.9);
  }
`;

const Tile = ({ img, top, mid, bottom }) => {
  return (
    <CardWrapper
      data-sal="slide-up"
      data-sal-delay="200"
      data-sal-easing="ease"
      data-sal-duration="500"
    >
      <Card>
        <CardFront img={img} />
        <CardBack>
          <CardDepth>
            <SubTitle>{top}</SubTitle>
            <StyledTitle>{mid}</StyledTitle>
            <SubTitle>{bottom}</SubTitle>
          </CardDepth>
        </CardBack>
      </Card>
    </CardWrapper>
  );
};

export default Tile;
