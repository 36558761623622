import styled from "styled-components";
import typographySizes from "src/assets/styles/typographySizes";
import colors from "src/assets/styles/colors";
import mediaQuery from "src/assets/styles/mediaQuery";

const BodyText = styled.p`
  font-size: ${typographySizes.s}rem;
  font-weight: 400;
  line-height: 1.5;
  color: ${colors.green};
  font-family: "sofia-pro", sans-serif;
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
  }
`;

export default BodyText;
