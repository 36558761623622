import React from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import fb from "src/assets/images/svg/fb_green.svg";
import instagram from "src/assets/images/svg/insa_green.svg";
import leaf from "src/assets/images/svg/footer-leaf.svg";
import Title from "src/components/typography/Title.js";

import BodyText from "src/components/typography/BodyText.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import mianInfo from "src/assets/data/mainInfo.js";
import Leaf from "src/assets/images/svg/footer-leaf.inline.svg";

const Wrapper = styled.footer`
  background-color: ${colors.green};
`;

const StyledMainWrapper = styled(MainWrapper)`
  position: relative;
`;

const SmallTextTop = styled.span`
  font-size: ${typographySizes.s}rem;
  color: ${colors.pink};
  font-family: "sofia-pro", sans-serif;
  padding-top: ${distances.gap}rem;
  display: inline-block;
  margin-bottom: 95rem;
`;

const StyledTitle = styled(Title)`
  max-width: 540rem;
  margin-right: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 350rem;
  }
`;

const ContactWrapper = styled.div`
  display: flex;
  padding-bottom: 75rem;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  /* background-image: url(${leaf}); */
  /* background-position: bottom right; */
  /* background-repeat: no-repeat; */
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    flex-wrap: wrap;
  }
`;

const LeafWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 320rem;
`;

const ContactInfo = styled.div`
  width: 50%;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const ContacktLink = styled.a`
  font-size: ${typographySizes.m}rem;
  font-family: "sofia-pro", sans-serif;
  color: ${colors.light};
  display: block;
  margin-top: 30rem;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  :hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const BottomBar = styled.div`
  padding: ${distances.gap}rem;
  padding-bottom: 100rem;
  background-color: ${colors.light};
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    padding-bottom: 50rem;
  }
`;

const RightsWrapper = styled.div`
  width: 50%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const We3 = styled(BodyText)`
  font-size: 15rem;
  a {
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    font-weight: inherit;
  }
`;

const OpenWrapper = styled.div`
  width: 33.33%;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  width: 16.66%;
  justify-content: flex-end;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
  }
`;

const SocialLink = styled.a`
  margin-left: ${distances.gap / 2}rem;
`;

const Footer = () => {
  return (
    <Wrapper id="kontakt">
      <StyledMainWrapper>
        <SmallTextTop
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          kontakt
        </SmallTextTop>
        <ContactWrapper>
          <LeafWrapper
            data-sal="slide-up"
            data-sal-easing="ease"
            data-sal-duration="500"
            data-sal-delay="800"
          >
            {" "}
            <Leaf />{" "}
          </LeafWrapper>
          <StyledTitle>zadzwoń, napisz, wpadnij na lunch</StyledTitle>
          <ContactInfo>
            <ContacktLink
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="500"
              href={`tel:${mianInfo.tel}`}
            >
              {mianInfo.tel}
            </ContacktLink>
            <ContacktLink
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="500"
              href={`mailto:${mianInfo.mail}`}
            >
              {mianInfo.mail}
            </ContacktLink>
            <ContacktLink
              data-sal="slide-up"
              data-sal-easing="ease"
              data-sal-duration="500"
              href={`${mianInfo.addressGoogle}`}
              target="_blank"
            >
              {mianInfo.address}
            </ContacktLink>
          </ContactInfo>
        </ContactWrapper>
        <BottomBar
          data-sal="slide-up"
          data-sal-easing="ease"
          data-sal-duration="500"
        >
          <RightsWrapper>
            <BodyText>©2021 - pink vegan | all right reserved</BodyText>
            <We3>
              {" "}
              projekt i wdorżenie:{" "}
              <a href="https://we3studio.pl/" target="_blank">
                we3studio.pl{" "}
              </a>{" "}
            </We3>
          </RightsWrapper>
          <OpenWrapper>
            {mianInfo.open.map((day) => (
              <BodyText>
                {day.day}: {day.hours}{" "}
              </BodyText>
            ))}
          </OpenWrapper>
          <SocialWrapper>
            <SocialLink href={mianInfo.facebook} target="_blank">
              <img src={fb} alt="facebook icon" />
            </SocialLink>
            <SocialLink href={mianInfo.instagram} target="_blank">
              <img src={instagram} alt="facebook icon" />
            </SocialLink>
          </SocialWrapper>
        </BottomBar>
      </StyledMainWrapper>
    </Wrapper>
  );
};

export default Footer;
